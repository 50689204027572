<template>
<div>
    <v-snackbar v-model="snackbar" :top=true :color="color" :timeout=6000>
      <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

  <v-data-table
    :headers="headers"
    :items="country_super_master_list"
    class="elevation-1"
     :search="search"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Country</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details>
          </v-text-field>
          <span style="width: 20px"></span>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">Add Country </v-btn>
          </template>
          <v-card>
            <v-form v-model="valid">
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.country_super_master" 
                    persistent-hint
                    :hint="editedIndex==-1?'For example :: India, Nepal, Srilanka':'For example :: India, Nepal, Srilanka'"
                    label="Enter Country"
                    :rules="[v => !!v || 'required' ]"
                    >
                    </v-text-field>
                  </v-col>
                    <v-col cols="12" sm="12" md="12">
                        <v-text-field v-model="editedItem.nationality" 
                        persistent-hint
                        :hint="editedIndex==-1?'For example :: IN for India, SK for Srilanka':'For example :: IN for India, SK for Srilanka'"
                        label="Enter Nationality"
                        :rules="[v => !!v || 'required' ]"
                        >
                        </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" v-if='editedIndex != -1'>
                    <v-checkbox
                        dense
                        v-model="editedItem.isactive"
                        color="success"
                        label="Active"
                      ></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="primary darken-1"  @click="save" :disabled="!valid">Save</v-btn>
            </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

         <template v-slot:item.isactive="{ item }">
            <span v-if="item.isactive">
              <v-icon  class="mr-2  v-icon-size" color="success">mdi-check-circle-outline</v-icon>
            </span>
            <span v-else>
              <v-icon  class="mr-2  v-icon-size" color="error">mdi-close-circle-outline</v-icon>
            </span>
          </template> -->

    <template v-slot:item.action="{ item }">
     <div>
        <v-avatar style="" size="25" class="edit-avatar">
          <v-icon class="edit-v-icon mr-2" @click="editItem(item)">edit</v-icon>
       </v-avatar>
      </div>
      <!-- <v-icon
        small
        @click="deleteItem(item)"
      >
        delete
      </v-icon> -->
    </template>
    <!-- <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">Reset</v-btn>
    </template> -->
  </v-data-table>
  <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</div>
</template>

<script>
import axios from 'axios'
  export default {
    data: () => ({
    valid: false,
    overlay: false,
    errorMsg: {
    country_super_master: false
    },
      snackbar_msg: "",
      color: "",
      snackbar: false,
      search:'',
      dialog: false,
      headers: [
        {
          text: 'Country',
          align: 'left',
          sortable: false,
          value: 'country_super_master',
        },
        {
          text: 'Nationalty',
          align: 'left',
          sortable: false,
          value: 'nationality',
        },
        {
         text: 'Active',align: 'left',sortable: true,value: 'isactive',
        },
        { text: 'Actions', value: 'action', sortable: false },
      ],
      country_super_master_list: [],
      editedIndex: -1,
      editedItem: {
        countryId: '',
        country_super_master: '',
        nationality:'',
        isactive:true  
      },
      defaultItem: {
        countryId: '',
        country_super_master: '',
        nationality:'',
        isactive:true      
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Country' : 'Edit Country'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      overlay (val) {
        val= () => {
          this.overlay = false
        };
      },
    },

    // created () {
    //   this.initialize()
    // },
    mounted(){
      this.onLoad()
     },
    methods: {
    initializeErrorMsgFalse() {
      this.errorMsg.standard = false;
    },
    onLoad(){
      this.overlay=true;
      axios
      .post("/Admin/adminCountry")
      .then(res => {
        if (res.data.msg == "200") {   
          if(this.load = true){
            this.overlay= false;
            this.country_super_master_list = res.data.country_super_master_list;
          }
        } else {
        }
      })
      .catch(error => {
        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
        // window.console.log(error);
      })
      .finally(()=>{ 
        var overlay=false;
      });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },

    editItem (item) {
        this.editedIndex = this.country_super_master_list.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
    },

      // deleteItem (item) {
      //   const index = this.language_super_master_list.indexOf(item)
      //   confirm('Are you sure you want to delete this item?') && this.language_super_master_list.splice(index, 1)
      // },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      save() {
        if (this.editedIndex > -1) {
          if (this.isValidated()) {
            axios
              .post("/Admin/editAdminCountry", this.editedItem)
              .then(res => {
                if (res.data.status.code == "SUCCESS") {
                  Object.assign(
                    this.country_super_master_list[this.editedIndex],
                    this.editedItem
                  );
                  this.showSnackbar(
                    "#4caf50",
                    "Country Details Updated Successfully..."
                  ); // show snackbar on success
                }
                else if (res.data.status.code == "NA") {
                  this.showSnackbar("#b71c1c", "Country Details Already Present!!!"); // show snackbar on error
                }
              })
              .catch(error => {
                window.console.log(error);
              });

            this.close();
          }
        } else {
            axios
              .post("/Admin/saveAdminCountry", this.editedItem)
              .then(res => {
                if (res.data.status.code == "SUCCESS") {
                  this.country_super_master_list.push(this.editedItem);
                  this.showSnackbar("#4caf50", "Country Details Added Successfully..."); // show snackbar on success
                  this.onLoad()
                } else if (res.data.status.code == "NA") {
                  this.showSnackbar("#b71c1c", "Country Details Already Present!!!"); // show snackbar on error
                }
              })
              .catch(error => {
                window.console.log(error);
              });
            this.close();
        }
      }, // end of save()

      isValidated() {
        if (this.editedItem.countryId) {
          return true;
        } else {
          if (!this.editedItem.countryId) {
            this.errorMsg.countryId = true;
          }

          return false;
        }
      } // .....end of isValidated()
    },
  }
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.v-icon-size{
  font-size: 20px;
}
.edit-avatar{
  transition: 0.3s;
  border: 1px solid #b0bec5;
}
.edit-avatar:hover{
  background-color: #b0bec5;
}
.edit-v-icon{
  padding-left: 6px;
  font-size: 15px;
}
.edit-v-icon:hover{
  color: white;
}
</style>